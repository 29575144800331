import React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/layout';

import linkedImg from "../images/icon-team-linked.svg"
import mailImg from "../images/icon-mail.svg"

import './teammember.scss';

export default ({pageContext}) => (
    <Layout>
        <div className="teammember-wrapper">
            <div className="container">
                <div className="row">
                    <div className="col-md-4">
                        <img src={pageContext.featured_media.localFile.childImageSharp.sizes.src} alt={pageContext.title} className="headshot" />
                    </div>
                    <div className="col-md-8">
                        <h1>{pageContext.title}</h1>
                        <h2>{pageContext.acf.title}</h2>
                        <div className="links">
                            {pageContext.acf.linkedin_profile_url &&
                            <a href={pageContext.acf.linkedin_profile_url} className="" target="_blank" rel="noopener noreferrer">
                                <img src={linkedImg} alt="LinkedIn"/>
                            </a>
                            }
                            {pageContext.acf.email_address &&
                            <a href={`mailto:` + pageContext.acf.email_address + `?subject=I saw your profile on MeritMile.com`}>
                                <img src={mailImg} alt="Email"/>
                            </a>
                            }
                        </div>
                    </div>
                </div>
                <div className="row">                    
                    <div className="col">
                        <div className="content-block" dangerouslySetInnerHTML={{__html: pageContext.content}}></div>
                    </div>
                </div>
                <div className="row margin-b">
                    <div className="col text-center">
                        <p className="text-center">
                            <Link 
                                to="/team/" 
                                className="btn btn-primary btn-lg">
                                    BACK TO TEAM PAGE
                            </Link>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
)
